<style>
.chat {
  position: fixed !important;
}
</style>

<template>
    <div id="cv_comunicador"></div>
</template>

<script>
export default {
  name: 'ChatBotWidget',

  mounted() {
    const script = document.createElement('script');
    script.src = 'https://crc.cvcrm.com.br/plugins/cv_comunicador/cvchatkit.umd.js';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      // eslint-disable-next-line no-undef
      const chat = new cvchat();
      chat.created({
        url_sistema: "https://crc.cvcrm.com.br",
        corFundo:"#6933a1",
        btCorFundo:"#472070",
        tipo:"chat",
        textoInicial:"Olá, como posso te ajudar?"
      });
    };
  }
};
</script>