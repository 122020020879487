<template>
    <section class="implantacao">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="title mt-5 mb-4 text-center">
                        <h2>
                            Implantação
                        </h2>
                    </div>
                </div>
                <div class="col-lg-12" v-for="(img, index) in img" :key="index">
                    <!-- <img :src="require('@/assets/imgs/banner_novo.jpeg')" alt="Imagem"> -->
                    <img :src="img.implantacao" alt="Imagem">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import '../Implantacao/StyleImplantacao.css';
import axios from 'axios';

const URL = 'https://api.crcevoce.com.br/wp-json/ag/v1/home';
export default {
    name: 'ImplantacaoVue',
    data() {
        return {
            img: []
        }
    },
    mounted() {
        axios.get(URL).then(response => {
            this.img = response.data[0].corpo.map(implantacao => ({
                implantacao: implantacao.imagem_implantacao
            }))
        })
    }
}
</script>