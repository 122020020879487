<template>
    <section class="header desk">
        <div class="container">
            <div class="row">
                <div class="body-header">
                    <div class="logo">
                        <img :src="require('@/assets/imgs/logo.png')" alt="Logo" width="121" height="121" v-once>
                    </div>
                    <div class="btn-especialista">
                        <img :src="require('@/assets/imgs/JJ.png')" alt="JJ Logo" width="126" class="me-4">
                        <img :src="require('@/assets/imgs/CRC1.png')" alt="CRC IMAGE" class="me-5" loading="lazy" width="126" height="57" v-once>
                        <a href="javascript:;" @click="scrollTo('especialista')" v-once>
                            FALE COM UM ESPECIALISTA
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="header mobile">
        <div class="container">
            <div class="row">
                <div class="body-header">
                    <div class="logo">
                        <img :src="require('@/assets/imgs/logo.png')" alt="Logo" width="121" height="121" v-once>
                    </div>
                    <div class="btn-menu">
                        <button class="openMenu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="responsive my-5">
                    <div class="btn-especialista">
                        <div>
                            <img :src="require('@/assets/imgs/CRC1.png')" alt="CRC IMAGE" loading="lazy" width="126" height="57" v-once>
                        </div>
                        <a href="#especialista" v-once class="mt-3">
                            FALE COM UM ESPECIALISTA
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</template>

<script>
import '@/components/Header/Style.css';
export default {
    name: 'HeaderVue',
    methods: {
        scrollTo(id) {
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({behavior:'smooth'});
            }
        }
    }
}
</script>