<template>
    <section class="galeria">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mt-5 mb-4">
                    <div class="titulos" v-once>
                        <div class="titulo1">
                            <h2 v-once>
                                LOCALIZAÇÃO <br>
                                PRIVILEGIADA
                            </h2>
                        </div>
                        <div class="titulo2">
                            <h2 v-once>
                                NA ARUANA. PERTINHO DO MAR E DE <br> TUDO QUE VOCÊ PRECISA
                            </h2>
                        </div>
                    </div>
                </div>
                <div id="carouselExample" class="carousel slide">
                    <div class="carousel-inner" v-for="(imagem, index) in galeria" :key="index">
                        <div class="carousel-item" :class="{ active: index === 0 }" v-for="(imagens, index) in imagem.galerias" :key="index">
                            <div class="thumb-carousel" :style="{background: `url(${imagens.url})`}">
                                <div class="legenda" v-if="imagens.legenda != ''">
                                    <span>
                                        {{ imagens.legenda }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <div class="prev-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                <path d="M29.4184 11.7612L17.9184 23.2612L29.4184 34.7612" stroke="#E3841F" stroke-width="2"/>
                            </svg>
                        </div>
                    </button>

                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <div class="next-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                <path d="M17.8949 34.761L29.3949 23.261L17.8949 11.761" stroke="#E3841F" stroke-width="2"/>
                            </svg>  
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import '@/components/Carrossel/StyleCarrossel.css';
import axios from 'axios';

const URL = 'https://api.crcevoce.com.br/wp-json/ag/v1/home';
export default {
    name:'CarrosselVue',
    data() {
        return {
            galeria: []
        }
    },
    mounted() {
        axios.get(URL).then(response => {
            this.galeria = response.data[0].corpo.map(corpo => ({
                galerias: corpo.galeria.map(imagens => ({
                    url: imagens.url,
                    legenda: imagens.caption
                }))
            }))
        })
    }
}
</script>