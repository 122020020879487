<template>
    <footer>
        <div class="footer">
            <div class="container">
                <div class="row align-items-center" v-for="(footer, index) in informacoes" :key="index">
                    <div class="col-lg-4 mt-5">
                        <ul class="lista-footer" role="list">
                            <li role="listitem">
                                {{ footer.texto_vendas }}
                            </li>

                            <li role="listitem" class="titulo-footer mt-3" v-once>
                                VENDAS
                            </li>

                            <li role="listitem">
                                {{ footer.endereco }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 mt-5 img-logo">
                        <img :src="require('@/assets/imgs/logo.png')" alt="Logo" loading="lazy" width="121" height="121" v-once>
                    </div>
                    <div class="col-lg-4 mt-5">
                        <ul class="lista-footer" role="list">
                            <li role="listitem" class="titulo-footer" v-once>
                                MAIS INFORMAÇÕES
                            </li>
                            <li role="listitem" class="numero">
                                <a :href="'tel:79' + footer.link_telefone">
                                    79 <span>{{ footer.telefone }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="border"></div>
                    <div class="copy my-3">
                        <div>
                            <span class="copyright" v-once>
                                ©Copyright <span class="year">{{ year }}</span> - Todos os direitos reservados a <strong>Aruana Garden</strong>.
                            </span>
                        </div>
                        <div>
                            <a href="https://alfamaweb.com.br" target="_blank" v-once>
                                <img :src="require('@/assets/imgs/logo-alfama.png')" alt="Alfama" width="100" height="14">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <BtnTop/>
    <ChatBotWidget/>
    
</template>
<script>
import '@/components/Footer/StyleFooter.css';
import BtnTop from '@/components/BtnReturn/BtnReturn.vue';

import axios from 'axios';
import ChatBotWidget from '../ChatBotWidget/ChatBotWidget.vue';

const URL = 'https://api.crcevoce.com.br/wp-json/ag/v1/home';
export default {
    name: 'FooterVue',
    components: {
    BtnTop,
    ChatBotWidget
},
    data() {
        return {
            informacoes: [],
            year: ''
        }
    },
    mounted() {
        axios.get(URL).then(response => {
            this.informacoes = response.data[0].corpo.map(info => ({
                telefone: info.telefone,
                link_telefone: info.link_telefone,
                texto_vendas: info.texto_vendas,
                endereco: info.endereco
            }))
        });

        this.year = new Date().getFullYear();        
        
    }
}

</script>


