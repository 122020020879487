<template>
    <section class="galeria-plantas">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="title text-center mt-5 mb-4">
                        <h2>
                            Galeria de Plantas
                        </h2>
                    </div>
                </div>
                <div id="carouselExample1" class="carousel slide mb-5">
                    <div class="carousel-inner" v-for="(imagem, index) in galObras" :key="index">
                        <div class="carousel-item" :class="{ active: index === 0 }" v-for="(imagens, index) in imagem.galerias" :key="index">
                            <div class="thumb-carousel" :style="{background: `url(${imagens.imagem})`}">
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample1" data-bs-slide="prev">
                        <div class="prev-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                <path d="M29.4184 11.7612L17.9184 23.2612L29.4184 34.7612" stroke="#E3841F" stroke-width="2"/>
                            </svg>
                        </div>
                    </button>

                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample1" data-bs-slide="next">
                        <div class="next-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                <path d="M17.8949 34.761L29.3949 23.261L17.8949 11.761" stroke="#E3841F" stroke-width="2"/>
                            </svg>  
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import '../GaleriaPlantas/StyleGaleriaObras.css';
import axios from 'axios';

const URL = 'https://api.crcevoce.com.br/wp-json/ag/v1/home';
export default {
    name: 'GaleriadePlantas',
    data() {
        return {
            galObras: []
        }
    },
    mounted() {
        axios.get(URL).then(response => {
            this.galObras = response.data[0].corpo.map(corpo => ({
                galerias: corpo.galeria_de_obras.map(imagens => ({
                    imagem: imagens
                }))
            }))
        })
    }
}
</script>